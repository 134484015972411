import React, { useEffect, useState } from 'react'
import Components from 'components/components.js'
import { graphql } from 'gatsby'

import Layout from '../layout'
import Header from '../components/Navbar'
import Footer from '../components/Footer'

import ContentContext from 'lib/content.context'
import { parseStoryblokResults } from 'utils/helpers'
import getParllayScriptByVersion from 'utils/getParllayScriptByVersion'
import getBaiduTrackingTag from 'utils/getBaiduTrackingTag'
import { formatTime } from '../components/Events/utils'
import { enUS, pt, zhCN } from 'date-fns/locale'

const locales = {
  en: enUS,
  pt,
  zh: zhCN,
}

const GATSBY_CHINA_DEPLOY = process.env.GATSBY_CHINA_DEPLOY

function StoryblokEntry(props) {
  const [state, setState] = useState(prepareStory(props))

  const { lang, allLanguages, page_version } = props.pageContext
  const { location } = props
  const { content, layout, first_published_at, published_at } = state.story
  // TODO: Comment this to run microservice without build issues.
  const allEvents = parseStoryblokResults(props.data.allEvents.edges)
  const allNews = parseStoryblokResults(props.data.allNews.edges)
  const allCommunityNews = parseStoryblokResults(
    props.data.allCommunityNews.edges,
  )
  const allOpenEd = parseStoryblokResults(props.data.allOpenEd.edges)
  const allAonStories = parseStoryblokResults(props.data.allAonStories.edges)
  const allTeamProfiles = parseStoryblokResults(
    props.data.allTeamProfiles.edges,
  )
  const allCategories = props.data.allCategories
    ? props.data.allCategories.edges
    : []

  // const allSmartJobs = props.data.allSmartRecruiterJobs
  //   ? props.data.allSmartRecruiterJobs.edges
  //   : []

  // Set locale for the internation Date/Time function
  const locale = locales[lang.replace('default', 'en')]
  // If utilizing the Event component, the below data will be passed via Context to the HubSpot Form Component
  const eventData =
    content.component === 'event_new'
      ? {
        component: content.component,
        external_title: content.name + ' ' + content.subheadline,
        name: content.location + ' ' + content.date,
        date: content.date.slice(0, -6),
        date_end: content.date_end,
        time:
            formatTime(new Date(content.date.replace(/-/g, '/')), 'p', locale) +
            ' - ' +
            formatTime(
              new Date(content.date_end.replace(/-/g, '/')),
              'p',
              locale,
            ),
        timezone_override: content.timezone_override,
        description: content.description,
        location: content.location,
        category: content.event_category,
        link: content.zoom_link?.url,
        street: content.event_street_address,
        city: content.event_city,
        state: content.event_state_region,
        zip: content.event_postal_code,
        page_slug: state.story.fields.full_slug,
        max_guests: content.max_guests,
        zoom_passcode: content.zoom_passcode,
        calendar_time: content.date,
        display_date: formatTime(
          new Date(content.date.replace(/-/g, '/')),
          'PPP',
          locale,
        ),
      }
      : content.component === 'event'
        ? {
          component: content.component,
          external_title: content.name,
          name: content.hubspot_name,
          description: content.page[0]?.seo[0]?.description
            ? content.page[0].seo[0].description
            : 'An event hosted by Avenues: The World School!',
          date: content.date,
          time: content.time,
          location: content.location,
          timezone: content.timezone,
          category: content.event_category,
          link: content.zoom_link?.url,
          street: content.event_street_address,
          city: content.event_city,
          state: content.event_state_region,
          zip: content.event_postal_code,
          page_slug: state.story.fields.full_slug,
          max_guests: content.max_guests,
          zoom_passcode: content.zoom_passcode,
          sp_date: content.sp_date,
        }
        : ''

  useEffect(() => {
    // Inject parllay code where is needed
    page_version && injectParllayTrackingCode(page_version)
    GATSBY_CHINA_DEPLOY === 'true' && injectBaiduTrackingTag()
  }, [])

  useEffect(() => {
    if (state.story.uuid !== props.pageContext.story.uuid) {
      setState(prepareStory(props))
    }
  }, [props.pageContext.story.uuid])

  function prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    story.content = JSON.parse(story.content)

    const layout = Object.assign({}, props.pageContext.layout)
    story.layout = JSON.parse(layout.content)

    return { story, layout }
  }

  function injectParllayTrackingCode(pageVersion) {
    const parllayScriptHTML = getParllayScriptByVersion(pageVersion)
    if (parllayScriptHTML) {
      const customScript = document.createElement('script')
      customScript.innerHTML = parllayScriptHTML
      document.body.appendChild(customScript)
    }
  }

  function injectBaiduTrackingTag() {
    const baiduScriptHTML = getBaiduTrackingTag()
    const customScript = document.createElement('script')
    customScript.innerHTML = baiduScriptHTML
    document.body.appendChild(customScript)
  }

  return (
    <Layout lang={lang}>
      {layout.header_v2[0] &&
        React.createElement(Header, {
          key: layout.header_v2[0]._uid,
          blok: layout.header_v2[0],
          content: { ...content },
          lang,
          allLanguages,
          location,
          altLink: state.story.content.link_althome,
          altApply: state.story.content.button_altApply
            ? state.story.content.button_altApply[0]
            : false,
        })}

      {/* TODO: Empty array as fallback in case of missing bloks. */}
      <ContentContext.Provider
        value={{
          allEvents,
          allNews,
          allCommunityNews,
          allOpenEd,
          allAonStories,
          allCategories,
          // allSmartJobs,
          eventData,
          allTeamProfiles,
        }}
      >
        {process.env.GATSBY_CHINA_DEPLOY && (
          <div id="wechat">
            <div className="qr">
              <img src="//a.storyblok.com/f/112543/430x430/622d2f2c82/m_officialwebsite.png" />
            </div>
            <div className="logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z" />
              </svg>
            </div>
          </div>
        )}
        {React.createElement(Components[content.component], {
          key: content._uid,
          blok: {
            ...content,
            first_published_at,
            published_at,
            page_version,
          },
          location,
          lang,
          allLanguages: allLanguages,
          schemaLD: [layout.sd_default ? JSON.parse(layout.sd_default) : {}],
        })}
      </ContentContext.Provider>

      {layout.footer[0] &&
        React.createElement(Footer, {
          key: layout.footer[0]._uid,
          blok: layout.footer[0],
          emailFooter: state.story.content.email_footer,
          phoneFooter: state.story.content.phone_footer,
        })}
    </Layout>
  )
}

export default StoryblokEntry

// TODO: Comment this to run microservice without build issues.
export const query = graphql`
  query RootQuery($lang: String) {
    #
    # EVENTS
    #
    allEvents: allStoryblokEntry(
      filter: {
        full_slug: { regex: "/events//" }
        name: { ne: "index" }
        lang: { eq: $lang }
        # field_date_string: { gt: $buildDate }
      }
      sort: { fields: field_date_string, order: ASC }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
        }
      }
    }
    #
    # CATEGORIES
    #
    allCategories: allStoryblokDatasource(
      filter: { data_source: { eq: "categories" } }
    ) {
      edges {
        node {
          name
          value
        }
      }
    }
    #
    # NEWS
    #
    allNews: allStoryblokEntry(
      filter: {
        field_component: { eq: "news" }
        slug: { ne: "single-news" }
        lang: { eq: $lang }
      }
      sort: { fields: first_published_at, order: DESC }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
        }
      }
    }
    #
    #
    # Team Profiles
    #
    allTeamProfiles: allStoryblokEntry(
      filter: {
        field_component: { eq: "team_member_profile" }
        lang: { eq: $lang }
      }
      sort: { fields: first_published_at, order: DESC }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
        }
      }
    }
    allCommunityNews: allStoryblokEntry(
      filter: {
        field_component: { eq: "community_news" }
        slug: { ne: "single-community-news" }
        lang: { eq: $lang }
      }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
          first_published_at
          id
        }
      }
    }
    allOpenEd: allStoryblokEntry(
      filter: {
        field_component: { eq: "opened" }
        slug: { ne: "single-community-news" }
        lang: { eq: $lang }
      }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
          first_published_at
          id
        }
      }
    }
    allAonStories: allStoryblokEntry(
      filter: {
        field_component: { eq: "aon_story" }
        slug: { ne: "single-community-news" }
        lang: { eq: $lang }
      }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
          first_published_at
          id
        }
      }
    }
  }
`
