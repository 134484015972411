export default pageVersion => `
  var _hmt = _hmt || [];
  (function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?583f6c8182ad69e470aa44cdb24c8c5b";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
  })();

  window._agl = 
window._agl || []; (function ()
{ _agl.push( ['production',

'_f7L2XwGXjyszb4d1e2oxPybgD'] ); (function ()
{ 
var agl = 
document.createElement('script'); agl.type =

'text/javascript'; agl.async = 
true; agl.src = 
'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';

var s = 
document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(agl,
 s); })(); })();
`
